const API_PREFIX = process.env.REACT_APP_BASE_API_URL;

export const OKTA_URL = `${API_PREFIX}/auth/login/okta-oauth2`;
export const AUTH0_URL = `${API_PREFIX}/auth/login/auth0`;
export const TOKEN_PATH = '/auth/token';
export const LOGOUT_URL = `${API_PREFIX}/auth/logout`;
export const ME_PATH = '/users/me';
export const PROJECTS_PATH = '/projects';
export const DATA_SOURCES_PATH = '/datasources';
export const PAGES_PATH = '/pages';
export const FILTERS_PATH = '/filters';
export const TAG_CATEGORIES_PATH = '/tag-categories';
export const DATA_WRANGLING_SCRIPTS_PATH = '/script';
export const DATA_WRANGLING_JOB_PATH = '/job';
export const PREVIEW_PATH = '/preview';
export const STATES_PATH = '/states';
export const RESET_PASSWORD_PATH = `${ME_PATH}/reset-password`;
export const BLOCK_TEMPLATES_PATH = '/block-templates';
export const PAGE_TEMPLATES_PATH = '/page-templates';
export const SECTIONS_PATH = '/sections';
export const TAGS_PATH = '/tags';
export const METADATA_PATH = '/description';
export const INITIAL_PAGE_SIZE = 10;
