export const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/404',
  AUTH: '/auth',
  NOT_AUTHORIZED: '/not-authorized',
  PROJECT: '/project',
  PROJECT_CREATE: '/project/create',
  RESET_PASSWORD: '/reset-password',
  LOGOUT: '/logout',
  DATA_SOURCE: '/datasource',
  FOLDER: '/folder',
  BLOCK: '/block',
  DATA_WRANGLING_SCRIPTS: '/script',
  SETTINGS: '/settings',
  USER: '/user',
  FILTER: '/filter',
  JOB_DETAIL: '/job',
  TAG_CATEGORY: '/tag-category',
  STATE: '/state',
  BLOCK_TEMPLATE: '/block-template',
  PAGE_TEMPLATE: '/page-template',
  SECTION: '/section',
  PAGE: '/page',
  ADD_BLOCK: '/add-block',
};
