/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'project.content.title',
    defaultMessage: 'Content',
  },
  subtitle: {
    id: 'project.content.subtitle',
    defaultMessage: 'Sections',
  },
  section: {
    id: 'project.content.section',
    defaultMessage: 'section',
  },
  pagesCounter: {
    id: 'project.content.pagesCounter',
    defaultMessage: '{pagesCount, plural,one {# Page} other {# Pages}}',
  },
});
