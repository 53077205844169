export const SETTINGS = 'settings';

export const SOURCES = 'sources';

export const USERS = 'users';

export const CONTENT = 'content';

export const TEMPLATES = 'templates';

export const TAG_CATEGORIES = 'tag-categories';
