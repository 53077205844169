export const colors = {
  white: '#ffffff',
  black: '#000000',
  grey: '#b7b7b7',
  red: '#a8241b',
  yellow: '#91880d',
  blue: '#1616ff',
  green: '#1a7113',
};

export const fonts = {
  arial: 'Arial',
};
